<template>
  <div>
    <tabla titulo="Recargas" 
          :headers = "headers"
          :arreglo = "RecargasGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import tabla from '@/components/recargas/';

export default {
   components: {
    tabla
  },
  
  created(){
    this.ConsultarRecargas()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'PUNTO DE VENTA', value: 'razon_social' },
        { text: 'USUARIO', value: 'nombre_usuario' },
        { text: 'TRANSACCIÓN', value: 'transaccion' },
        { text: 'DISPONIBLE ANT', value: 'saldo_anterior' },
        { text: 'VALOR', value: 'valor' },
        { text: 'DISPONIBLE NEW', value: 'nuevo_saldo' },
        { text: 'FECHA', value: 'fecha_registro_format' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions(['ConsultarRecargas']),

  },

  computed:{
      ...mapGetters(['RecargasGetter'])
  }
}
</script>
